body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

/* .bgImgCenter{
  background-image: url('./back.png');
  background-repeat: no-repeat;
  background-position: center; 
  background-color: #0A4B81;
  position: cover;
  height: 100%;


  background-image: url("img_girl.jpg");

 
  height: 100%;

  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.bgImgCentergame{
  background-image: url('./gameback.png');
  background-repeat: no-repeat;
  background-position: center; 
  background-color: #0A4B81;
  position: cover;
  height: 100vh;
} */
.ma5{
  margin: 7px;
}

.bg {

  background-image: url("./LOGIN-PAGE-1-without-anything.png");

  
  height: 100vh; 

 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.gameCol{
  height: 400px;
  width: 400px;
}
.gpadding {
  padding: 1rem 9rem 0rem 9rem !important;
}

.row,.col{
  margin: 0px;
  padding: 0px;
}

/* .centerDiv{
  position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
} */
.centerDiv {
  position: absolute;

  top: 20%;
  left: 50%;

}
.whiteborder{
  border: 6px solid white;
}
.bb{
  border: 2px solid transparent !important;
  border-bottom-color: black !important;
}

.purple{
  color: #0A4B81;
  font-weight: 800;
}


.pupleButton{

  background: #0A4B81;
}
.white{
  color: white;
}

.heading{

  font-size: 18px;
}

.rounded {
  border-radius: 1.25rem!important;
  border: 2px solid #0A4B81;
}

.gameColbutton{
width: 400px;
}

.tr{
  text-align: right;
}

input{
  height:26px
}

.mrg{
  margin-top: 140px;
}

.emg{
  margin-top: 15px;
}

.parent{
position: relative;
}
.over-img{
  position: absolute;

}


.imgp{
  height: 100%;
  width: 100%;
  
}
.backimgphoto { 




  /*      
    background: url('./photo-jacket-back.jpg') no-repeat;
      
    width: 100%;
    height:100vh;
    
   
    
    background-size:cover; */
  
  
    background: url('./pb1.jpg') no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    height: 110vh;
  
  }


  .menuname{
font-size: 12px;
font-weight: bold;
text-transform: uppercase;
color:white;
  }
  .footer {
    
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
 }

 .down{
   margin: 5px;
   height: 35px !important;
   cursor: pointer;
 }